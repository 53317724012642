export class QueuesService {
    // eslint-disable-next-line no-undef
    routingApi = new platformClient.RoutingApi();

    async getQueues() {
        try {
            let queues;
            let queueEntityList = await this.routingApi.getRoutingQueues();
            queues = queueEntityList.entities.map(e => ({ Name: e.name, Id: e.id }))
            return queues;
        } catch (error) {
            console.error(error);
        }
    }
}