import React from 'react';
import { Header } from './components/Header';
import { Dashboard } from './components/Dashboard';
import './App.css';





export class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalIsVisible: false
    }
    // eslint-disable-next-line no-undef
    this.pcApiClient = platformClient.ApiClient.instance;

    this.hideModal = this.hideModal.bind(this);
    this.setPurecloudEnv();
  }

  setPurecloudEnv() {
    this.pcApiClient.setEnvironment(this.props.auth.environment);
    this.pcApiClient.setAccessToken(this.props.auth.purecloudToken);
  }

  showModal() {
    this.setState({ modalIsVisible: true });
  }

  hideModal() {
    this.setState({ modalIsVisible: false });
  }

  render() {
    return (
      <div id='dialog-target'>
        <Header
          showModal={this.showModal.bind(this)}
        />

        <Dashboard
          panelSettings={this.state.settings}
          modalIsVisible={this.state.modalIsVisible}
          hideModal={this.hideModal}
        />
      </div>
    );
  }
}

export default App;

