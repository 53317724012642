import React from 'react';
import NotificationsService from '../services/notifications.service';
import { ConversationsService } from '../services/conversations.service';

import * as data from '../services/dataSource.json';

export class Panel extends React.Component {

    notifications = new NotificationsService();
    conversations = new ConversationsService(this.props.settings.metricsCode);
    metricsData = data['metrics'];

    constructor(props) {
        super(props);
        this.state = this.conversations.initStateFromSelectedMetrics();
    }

    componentDidMount() {
        this.conversations.getConversationMetrics()
            .then(convMetrics => {
                if (convMetrics) {
                    this.conversations.setStateByMetrics(convMetrics, true).then(m => this.setState(m));
                }
            });

        this.notifications.subscribeQueue(this.props.settings.queues[0], (event) => {
            let pcMetrics = event.eventBody.data[0].metrics;
            this.conversations.setStateByMetrics(pcMetrics).then(m => this.setState(m));
        });
    }

    setStateByMetrics(metrics) {
        let metricArrayToObj = Object.assign({}, ...metrics
            .filter(item => this.filterBySelectedMetrics(item))
            .map(o => this.setMetricsByStats(o)))
        if (Object.keys(metricArrayToObj).length !== 0 && metricArrayToObj.constructor === Object)
            this.setState(metricArrayToObj);
    }

    filterBySelectedMetrics(pureCloudMetrics) {
        return this.props.settings.metricsCode.filter(metric => metric.Code === pureCloudMetrics.metric).length > 0
    }

    convertTime(milliseconds) {
        let hours, minutes, seconds;
        seconds = Math.floor(milliseconds / 1000);
        minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;
        hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        hours = hours % 24;
        if (hours)
            return `${hours}h ${minutes}m ${seconds}s`;
        else if (minutes)
            return `${minutes}m ${seconds}s`;
        else if (seconds)
            return `${seconds}s`;
        return '-'
    }

    render() {
        return (
            <div id="table">
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th className='text-center'>Today</th>
                            <th className='text-center'>Interval</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.props.settings.metricsCode.map((metric, i) => {
                                return (


                                    <tr key={i}>
                                        <td>{this.metricsData.find(m => m.Code === metric.Code && m.Stats === metric.Stats).Name}</td>
                                        <td className='metric-value text-center'>
                                            {metric.Stats !== 'count' ? this.convertTime(this.state[metric.Code + metric.Stats + '_today']) : this.state[metric.Code + metric.Stats + '_today']}

                                        </td>
                                        <td className='metric-value text-center'>

                                            {metric.Stats !== 'count' ? this.convertTime(this.state[metric.Code + metric.Stats]) : this.state[metric.Code + metric.Stats]}

                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div >

        )
    }

}