
export class AuthService {
    urlParams;
    pcEnvironment;
    constructor() {
        this.urlParams = new URLSearchParams(window.location.search);
        this.pcEnvironment = this.urlParams.get('environment');
    }

    getAppUrl(pcEnvironment) {
        if (pcEnvironment === 'mypurecloud.ie') {
            return 'https://repoie.361cloudapp.com';
        } else if (this.pcEnvironment === 'mypurecloud.de') {
            return 'https://repode.361cloudapp.com';
        } else if (this.pcEnvironment === 'mypurecloud.jp') {
            return 'https://repojp.361cloudapp.com';
        } else if (this.pcEnvironment === 'usw2.pure.cloud') {
            return 'https://repous.361cloudapp.com';
        } else if (this.pcEnvironment === 'mypurecloud.com.au') {
            return 'https://repoau.361cloudapp.com';
        } else { return null; }
    }

    async loadAppConfig() {
        const response = await fetch('/config.json', {
            method: 'GET'
        });
        if (!response.ok) { throw response; }
        return response.json();
    }

    getTokenByCode(config) {
        const code = this.urlParams.get('code');
        if (!code || code === 'null' || code === 'undefined' || code === '') {
            console.log('NoralogixApp. Get new Purecloud auth code');
            window.location.replace(this.getAuthUrl('code', config));
            return Promise.resolve();
        } else {
            const state = this.urlParams.get('state');
            const authResponse = this.getPurecloudCodeResponse(state, code);
            window.history.replaceState({}, document.title, window.location.origin);
            return this.getApiTokenByCode(authResponse, config);
        }
    }

    async getApiTokenByCode(authResponse, config) {
        let pcEnv = authResponse.environment || config.purecloudEnvironment;
        const response = await fetch(`${config.loginApiUrl}&code=${authResponse.code}`
            + `&redirectUrl=${this.getBaseUrl()}&environment=${pcEnv}`, {
            method: 'GET',
            mode: 'cors'
        });
        if (response.status >= 200 && response.status <= 299)
            return Promise.resolve(response.json());

        // This will end up in ERROR part
        return Promise.resolve(response.json()).then(responseInJson => Promise.reject(responseInJson.message));
    }

    getPurecloudCodeResponse(state, code) {
        const codeResponse = new PurecloudCodeResponse();
        codeResponse.code = code;
        if (state) {
            const decodedState = decodeURIComponent(state);
            const params = decodedState.split('?');
            if (params.length === 2) {
                const search = decodeURIComponent(params[1]).split('&');
                codeResponse.environment = this.getSearchParameterByName(search, 'environment');
            }
        }
        return codeResponse;
    }

    getAuthUrl(response_type, config) {
        const state = encodeURIComponent(window.location.href);
        let pcEnv = this.pcEnvironment || config.purecloudEnvironment;
        console.log(state);
        return `https://login.${pcEnv}/oauth/authorize?response_type=${response_type}`
            + `&client_id=${config.purecloudClientId}`
            + `&redirect_uri=${this.getBaseUrl()}&state=${state}`;
    }

    getBaseUrl() {
        return window.location.origin;
    }

    getSearchParameterByName(search, name) {
        for (let i = 0; i < search.length; i++) {
            if (search[i].includes(name)) {
                return search[i].split('=')[1];
            }
        }
        return null;
    }

}

export class PurecloudCodeResponse {
    token;
    environment;
    code;
}

export default AuthService;