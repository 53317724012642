export class ConversationsService {
    //pcApiClient = platformClient.ApiClient.instance;
    // eslint-disable-next-line no-undef
    analyticsApi = new platformClient.AnalyticsApi();


    constructor(metricsCode) {
        this.selectedMetrics = metricsCode;
        this.selectedMetricsName = metricsCode.map(m => m.Code);
        this.convState = this.initStateFromSelectedMetrics();
        this.todayMetricsStats = {};
    }

    async getConversationMetrics() {
        let dateFrom = new Date();
        dateFrom.setHours(0, 0, 0, 0);
        let dateTo = this.roundTimeHalfHour(new Date()).toISOString();
        let interval = `${dateFrom.toISOString()}/${dateTo}`;
        let body = {
            interval: interval,
            groupBy: ['queueId'],
            metrics: this.selectedMetricsName
        }
        let metrics = null;
        try {
            let conversations = await this.analyticsApi.postAnalyticsConversationsAggregatesQuery(body);
            if (Object.keys(conversations).length !== 0)
                metrics = conversations?.results[0]?.data[0]?.metrics;
        } catch (error) {
            alert(error.body.message)
        }
        return metrics;
    }

    roundTimeHalfHour(time) {
        let minutes = time.getMinutes();
        time.setSeconds(0);
        time.setMilliseconds(0);
        if (minutes > 30)
            time.setMinutes(minutes - minutes + 30)
        else
            time.setMinutes(0)

        return time;
    }

    initStateFromSelectedMetrics() {
        return this.selectedMetrics.reduce((state, item) => Object.assign(state, { [item.Code + item.Stats]: 0 }, { [item.Code + item.Stats + '_today']: 0 }), {});
    }

    setStateByMetrics(pcMetrics, isToday = false) {
        this.selectedMetrics.forEach(metric => {
            let pcMetric = pcMetrics.find(pc => pc.metric === metric.Code);
            if (pcMetric) {
                if (isToday) {
                    this.convState = { ...this.convState, [metric.Code + metric.Stats + '_today']: this.setMetric(pcMetric, metric) };
                    this.todayMetricsStats = { ...this.todayMetricsStats, [metric.Code + metric.Stats + '_today']: pcMetric.stats };
                }
                else
                    this.convState = { ...this.convState, [metric.Code + metric.Stats]: this.setMetric(pcMetric, metric), [metric.Code + metric.Stats + '_today']: this.updateTodayMetric(pcMetric, metric) };
            }
        });
        return Promise.resolve(this.convState);
    }

    setMetric(pcMetric, selectedMetric) {
        switch (selectedMetric.Stats) {
            case 'total':
                return pcMetric.stats.sum;
            case 'max':
                return pcMetric.stats.max;
            case 'min':
                return pcMetric.stats.min;
            case 'count':
                return pcMetric.stats.count;
            case 'avg':
                return pcMetric.stats.sum / pcMetric.stats.count;
            default:
                break;
        }
    }

    updateTodayMetric(pcMetric, selectedMetric) {
        switch (selectedMetric.Stats) {
            case 'max':
                if (this.convState[selectedMetric.Code + selectedMetric.Stats + '_today']) {
                    let max = this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] > pcMetric.stats.max ? this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] : pcMetric.stats.max;
                    return max;
                }
                else
                    return pcMetric.stats.max;
            case 'min':
                if (this.convState[selectedMetric.Code + selectedMetric.Stats + '_today']) {
                    let min = this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] < pcMetric.stats.min ? this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] : pcMetric.stats.min;
                    return min;
                }
                else
                    return pcMetric.stats.max;
            case 'count':
                let todayValue = this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'];
                let currentValue = this.convState[selectedMetric.Code + selectedMetric.Stats];
                let newValue = pcMetric.stats.count;
                if (todayValue) {
                    if (newValue === currentValue)
                        return todayValue;
                    else
                        return todayValue + (newValue - currentValue);
                }
                else
                    return newValue;
            case 'avg':
                if (this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] && this.todayMetricsStats) {
                    let avg = (this.todayMetricsStats[selectedMetric.Code + selectedMetric.Stats + '_today'].sum + pcMetric.stats.sum) / (pcMetric.stats.count + this.todayMetricsStats[selectedMetric.Code + selectedMetric.Stats + '_today'].count);
                    return avg;
                }
                else
                    return pcMetric.stats.sum / pcMetric.stats.count;
            case 'total':
                // if (this.convState[selectedMetric.Code + selectedMetric.Stats + '_today']) {
                //     let total = this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'] + pcMetric.stats.sum;
                //     return total;
                // }
                // else
                //     return pcMetric.stats.sum;

                let todaySumValue = this.convState[selectedMetric.Code + selectedMetric.Stats + '_today'];
                let currentSumValue = this.convState[selectedMetric.Code + selectedMetric.Stats];
                let newSumValue = pcMetric.stats.sum;
                if (todaySumValue) {
                    if (newSumValue === currentSumValue)
                        return todaySumValue;
                    else
                        return todaySumValue + (newSumValue - currentSumValue);
                }
                else
                    return newSumValue;
            default:
                break;
        }

    }
}


