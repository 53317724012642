import React from "react";
import { DialogComponent } from '@syncfusion/ej2-react-popups';
import { MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs'
import { QueuesService } from '../services/queues.service';
import * as data from '../services/dataSource.json';

export class Modal extends React.Component {

    metricsData = data['metrics'];
    fields = { text: 'Name', value: 'Id' };

    buttons = [{
        buttonModel: {
            content: 'Save',
            cssClass: 'e-flat',
            isPrimary: true,
        },
        'click': () => {
            this.createPanel()
        }
    },
    {
        buttonModel: {
            content: 'Cancel',
            cssClass: 'e-flat'
        },
        'click': () => {
            this.props.hideModal()
        }
    }];

    queuesService = new QueuesService();

    constructor(props) {
        super(props);
        this.state = {
            title: '',
            queues: [],
            users: [],
            metricsCode: [],
            errors: {}
        }
        this.metricsItemTemplate = this.metricsItemTemplate.bind(this);
        this.onRemovedMetrics = this.onRemovedMetrics.bind(this);
        this.onSelectMetrics = this.onSelectMetrics.bind(this);
        this.onChangeQueues = this.onChangeQueues.bind(this);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onOpenMetrics = this.onOpenMetrics.bind(this);

        this.titleRef = React.createRef();
        this.queuesRef = React.createRef();
        this.metricsRef = React.createRef();
    }

    componentDidMount() {
        this.queuesService.getQueues().then(q => this.queuesData = q);
    }

    handleValidation() {
        let formIsValid = true;
        let errors = {};
        if (!this.state.title) {
            formIsValid = false;
            errors['title'] = 'Cannot be empty';
        }
        if (this.state.metricsCode.length <= 0) {
            formIsValid = false;
            errors['metricsCode'] = 'Cannot be empty';
        }
        if (this.state.queues.length <= 0) {
            formIsValid = false;
            errors['queues'] = 'Cannot be empty';
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    createPanel() {
        if (this.handleValidation()) {
            let settings = {
                metricsCode: this.state.metricsCode,
                title: this.state.title,
                queues: this.state.queues
            }
            this.titleRef.current.value = '';
            this.metricsRef.current.value = [];
            this.queuesRef.current.value = [];
            this.setState({ metricsCode: [] });
            this.props.hideModal();
            this.props.createPanel(settings);
        }
        return;
    }

    metricsItemTemplate(data) {
        return (<span><span className='name'>{data.Name}</span><span className='metric-group'>{data.Group}</span></span>);
    }

    onChangeTitle(event) {
        if (event.value)
            this.setState({ title: event.value })
    }

    onChangeQueues(event) {
        if (event.value)
            this.setState({ [event.element.id]: event.value })
        else
            this.setState({ [event.element.id]: [] })
    }

    onSelectMetrics(event) {
        if (event.itemData) {
            this.setState({ metricsCode: [...this.state.metricsCode, event.itemData] })
        }
    }

    onOpenMetrics() {
        if (this.state.metricsCode.length > 0)
            this.metricsRef.current.dataSource = this.metricsData.filter(m => m.Group === this.state.metricsCode[0].Group);
        else
            this.metricsRef.current.dataSource = this.metricsData;
    }

    onRemovedMetrics(event) {
        let metricsCodeState = this.state.metricsCode.filter(m => m !== event.itemData);
        this.setState({ metricsCode: metricsCodeState })
    }

    dialogContent() {
        return (
            <form>
                <TextBoxComponent
                    id="title"
                    ref={this.titleRef}
                    floatLabelType='Auto'
                    placeholder='Title'
                    change={this.onChangeTitle} />
                <div className='error-text'>{this.state.errors['title']}</div>

                <div id='q-con'>
                    {this.state.users.length === 0 &&
                        <React.Fragment>
                            <MultiSelectComponent ref={this.queuesRef} id="queues"
                                floatLabelType='Auto'
                                mode="Box"
                                placeholder='Queue'
                                maximumSelectionLength={1}
                                change={this.onChangeQueues}
                                dataSource={this.queuesData}
                                fields={this.fields} />
                            <div className='error-text'>{this.state.errors['queues']}</div>
                        </React.Fragment>
                    }
                </div>

                {/* <div id='u-con'>
                    {this.state.queues.length === 0 &&
                        <React.Fragment>
                            <MultiSelectComponent id="users" name='users'
                                floatLabelType='Auto'
                                mode="Box"
                                placeholder='User'
                                maximumSelectionLength={3}
                                change={this.onChangeQueues.bind(this)}
                                dataSource={this.metricsData} />
                            {/* <div id="usersError" /> */}
                {/* </React.Fragment>} */}
                {/* </div>  */}


                <div id='m-con'>
                    {(this.state.queues.length > 0 || this.state.users.length > 0) &&
                        <React.Fragment>
                            <MultiSelectComponent id="metricsCode" name='metrics'
                                floatLabelType='Auto'
                                mode="Box"
                                placeholder='Metrics'
                                fields={this.fields}
                                ref={this.metricsRef}
                                allowFiltering={true}
                                maximumSelectionLength={5}
                                dataSource={this.metricsData}
                                itemTemplate={this.metricsItemTemplate}
                                open={this.onOpenMetrics}
                                select={this.onSelectMetrics}
                                removed={this.onRemovedMetrics}
                            />
                            <div className='error-text'>{this.state.errors['metricsCode']}</div>
                        </React.Fragment>
                    }
                </div>
            </form >);
    }

    render() {
        return (
            <DialogComponent
                width='400px'
                isModal={true}
                visible={this.props.modalIsVisible}
                buttons={this.buttons}
            >
                <div>
                    {this.dialogContent()}
                </div>
            </DialogComponent>
        )
    }
}

