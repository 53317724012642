import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthService from './services/auth.service';


const auth = new AuthService();
auth.loadAppConfig()
  .then(config => {
    auth.getTokenByCode(config)
      .then(auth => {
        ReactDOM.render(
          <React.StrictMode>
            <App auth={auth} />
          </React.StrictMode>,
          document.getElementById('root')
        );
      })
  })
  .catch(error => console.error(error))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
