import React from 'react';
import { DashboardLayoutComponent } from '@syncfusion/ej2-react-layouts';
import { Modal } from './Modal';
import { Panel } from './Panel';

export class Dashboard extends React.Component {

    constructor(props) {
        super(props)

        this.dashBoardRef = React.createRef();
        this.onDashboardCreated = this.onDashboardCreated.bind(this);
        this.count = 0;
        this.cellSpacing = [20, 20];
        this.mediaQuery = 'max-width: 1220px';
        this.windowWidth = window.outerWidth;
        this.dashboardSettings = [];
    }

    createPanel(settings) {
        this.dashboardSettings.push(settings);
        localStorage.setItem('dashboardSettings', JSON.stringify(this.dashboardSettings));
        let panelId = 'panel' + this.count.toString();
        let panel = [{ "id": panelId, "sizeX": 1, "sizeY": 1, "row": 0, "col": 0, header: () => this.generatePanelHeader(panelId, settings), content: () => <Panel settings={settings} /> }];
        this.dashBoardRef.current.addPanel(panel[0]);
        this.count++;
    }

    generatePanelHeader(id, settings) {
        return (
            <React.Fragment>
                <h3 className='title'>{settings.title}</h3>
                <span className="action" onClick={() => this.deletePanel(id, settings)}>&times;</span>
            </React.Fragment>
        )
    }

    deletePanel(id, settings) {
        let confirmDelete = window.confirm(`Are you sure to delete ${settings.title}?`);
        if (confirmDelete) {
            this.dashBoardRef.current.removePanel(id);
            this.dashboardSettings = this.dashboardSettings.filter(s => s !== settings);
            localStorage.setItem('dashboardSettings', JSON.stringify(this.dashboardSettings));
        }
        else return;
    }

    componentDidMount() {
        this.handleCellAspectRatio(this.windowWidth);
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.handleResize);
    }

    handleCellAspectRatio(windowWidth) {
        if (this.dashBoardRef) {
            if (windowWidth >= 800 && windowWidth <= 1300) {
                this.dashBoardRef.current.cellAspectRatio = 50 / 150
            }
            else if (windowWidth >= 1300) {
                this.dashBoardRef.current.cellAspectRatio = 100 / 150
            }

        }
    }

    handleResize(event) {
        if (this.dashBoardRef)
            this.handleCellAspectRatio(event.currentTarget.window.outerWidth);
    }

    onDashboardCreated() {
        let storedDashboardSettings = JSON.parse(localStorage.getItem('dashboardSettings'));
        if (storedDashboardSettings) {
            storedDashboardSettings.forEach(settings => {
                this.createPanel(settings);
            });
        }
    }

    render() {
        return (
            <div className='p-10'>
                <Modal
                    modalIsVisible={this.props.modalIsVisible}
                    hideModal={this.props.hideModal}
                    createPanel={this.createPanel.bind(this)} />

                <DashboardLayoutComponent
                    id='dashboard_layout'
                    allowResizing={true}
                    ref={this.dashBoardRef}
                    mediaQuery={this.mediaQuery}
                    panels={this.panels}
                    cellSpacing={this.cellSpacing}
                    columns={5}
                    created={this.onDashboardCreated}
                />

            </div>
        );
    }
}