export class NotificationsService {
    //pcApiClient = platformClient.ApiClient.instance;
    // eslint-disable-next-line no-undef
    notificationsApi = new platformClient.NotificationsApi();
    notificationChannel;

    queuesSubscriptions = [];
    // Create notification channel
    async subscribeQueue(queueId, action) {
        try {
            let queuesTopic = `v2.analytics.queues.${queueId}.observations`;
            this.queuesSubscriptions.push({ queueId, action, queuesTopic });
            let subscriptions = this.queuesSubscriptions.filter(s => s.queueId === queueId)
            if (subscriptions.length > 1) return;
            if (!this.notificationChannel) {
                this.notificationChannel = await this.notificationsApi.postNotificationsChannels();
                console.log('channel: ', this.notificationChannel);

                // Set up web socket
                let webSocket = new WebSocket(this.notificationChannel.connectUri);

                // Subscribe to authenticated user's presence

                webSocket.onmessage = this.handleNotification.bind(this);
                webSocket.onclose = error => {
                    alert('Connection closed');
                    console.log(error);
                };

                console.log('Channel subscriptions set successfully');
            }
            const body = [{ id: queuesTopic }];
            let channelSubscriptions = await this.notificationsApi.putNotificationsChannelSubscriptions(this.notificationChannel.id, body);
            console.log(channelSubscriptions);
        } catch (error) {
            console.log(error);
        }

    }

    // Handle incoming Genesys Cloud notification from WebSocket
    handleNotification(message) {
        // Parse notification string to a JSON object
        const notification = JSON.parse(message.data);

        // Discard unwanted notifications
        if (notification.topicName.toLowerCase() === 'channel.metadata') {
            // Heartbeat
            // console.info('Ignoring metadata: ', notification);
            return;
        } else {
            let subscriptions = this.queuesSubscriptions.filter(s => s.queuesTopic === notification.topicName.toLowerCase())
            if (subscriptions.length > 0) {
                subscriptions.forEach(s => s.action(notification));
            } else {
                console.warn('Unknown notification: ', notification);
            }
        }
        // Log messages
        //console.log(`${new Date().toLocaleTimeString()} - ${JSON.stringify(notification, null, 2)}`);
    }
}

export default NotificationsService;