import React from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export const Header = ({ showModal }) => (
    <div className='p-10'>
        <ButtonComponent onClick={showModal}>Add metric</ButtonComponent>
    </div>
)




